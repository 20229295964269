<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'rec-post-edit-warn-modal',
  components: {
    BaseButton,
    BaseIcon,
    BaseMdl,
  },
  props: {
    type: {
      type: String,
      default: 'info',
      validator: (v) => /quiz|poll/.test(v),
    },
  },
  methods: {
    onClick() {
      this.$emit('continue')
      this.onClose()
    },
    onClose() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" width="xs">
    <div class="relative p-5 sm:p-[30px]">
      <div class="absolute top-0 right-0 m-5 sm:m-[30px]">
        <base-button icon="plain/close" @click="onClose" />
      </div>

      <div class="mt-[5px] mb-6 text-center">
        <base-icon :size="64" class="mx-auto text-fg-warning" svg="rec/edit" />
      </div>
      <div class="text-center">
        <h2 class="leading-tight">Do you wish to continue?</h2>
        <p class="mt-[30px]">
          Editing this
          <span class="capitalize">
            {{ type }}
          </span>
          will remove any responses.
        </p>
        <base-button class="mt-10" full-width @click="onClick">
          Continue
        </base-button>
      </div>
    </div>
  </base-mdl>
</template>
